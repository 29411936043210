// React
import { useState } from "react";

// MUI
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faXTwitter } from "@fortawesome/free-brands-svg-icons";

// Libraries
import { Link, useMatch } from "react-router-dom";

//The Header view
export const Header = () => {
  const homeRoute = useMatch("/");

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <div className="header-container">
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <AppBar position="relative" color="transparent" elevation={0}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
              >
                <Stack
                  direction="column"
                  spacing={-0.5}
                  justifyContent="center"
                  alignItems="center"
                  pr={3}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Typography color="primary" variant="h5" align="center">
                      黄泉の国
                    </Typography>
                    <Typography
                      color="primary"
                      variant="subtitle2"
                      align="center"
                    >
                      YOMI NO KUNI
                    </Typography>
                  </Link>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  flexGrow={1}
                >
                  {/* <Button
                    component={Link}
                    to="/roadmap"
                    color={roadmapRoute ? "secondary" : "primary"}
                  >
                    Roadmap
                  </Button> */}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  {/* <IconButton
                    color="primary"
                    target="_blank"
                    href="https://www.youtube.com/watch?v=TfNskerffGs&list=PL-3J4RsnLBl2wjlmapm5uyPGPfvyNgvzf"
                  >
                    <FontAwesomeIcon icon={faYoutube} size={"sm"} />
                  </IconButton> */}
                  <IconButton
                    color="primary"
                    target="_blank"
                    href="https://discord.gg/PW7CDyj2XU"
                  >
                    <FontAwesomeIcon icon={faDiscord} size={"sm"} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    target="_blank"
                    href="https://www.x.com/yominokunixyz"
                  >
                    <FontAwesomeIcon icon={faXTwitter} size={"sm"} />
                  </IconButton>

                  {/* <IconButton
                    color="primary"
                    target="_blank"
                    href="https://www.tiktok.com/yominokunixyz"
                  >
                    <FontAwesomeIcon icon={faTiktok} size={"sm"} />
                  </IconButton> */}
                </Stack>
              </Stack>
            </Box>
            <Box
              pt={2}
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={3}
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <IconButton onClick={handleOpenNavMenu} color="primary">
                    <FontAwesomeIcon icon={faBars} />
                  </IconButton>

                  <Menu
                    disableScrollLock={true}
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <MenuItem
                      key={0}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to="/"
                    >
                      <Typography
                        variant="button"
                        color={homeRoute ? "secondary" : "primary"}
                      >
                        Home
                      </Typography>
                    </MenuItem>

                    {/* <MenuItem
                      key={4}
                      onClick={handleCloseNavMenu}
                      component={Link}
                      to="/roadmap"
                    >
                      <Typography
                        variant="button"
                        color={roadmapRoute ? "secondary" : "primary"}
                      >
                        Roadmap
                      </Typography>
                    </MenuItem> */}

                    {/* <MenuItem key={7} onClick={handleCloseNavMenu}>
                      <IconButton
                        color="primary"
                        target="_blank"
                        href="https://www.youtube.com/watch?v=TfNskerffGs&list=PL-3J4RsnLBl2wjlmapm5uyPGPfvyNgvzf"
                      >
                        <FontAwesomeIcon icon={faYoutube} size={"sm"} />
                      </IconButton>
                    </MenuItem> */}

                    <MenuItem key={5} onClick={handleCloseNavMenu}>
                      <IconButton
                        color="primary"
                        target="_blank"
                        href="https://discord.gg/PW7CDyj2XU"
                      >
                        <FontAwesomeIcon icon={faDiscord} size={"sm"} />
                      </IconButton>
                    </MenuItem>
                    <MenuItem key={6} onClick={handleCloseNavMenu}>
                      <IconButton
                        color="primary"
                        target="_blank"
                        href="https://www.x.com/yominokunixyz"
                      >
                        <FontAwesomeIcon icon={faXTwitter} size={"sm"} />
                      </IconButton>
                    </MenuItem>
                    {/* <MenuItem key={7} onClick={handleCloseNavMenu}>
                      <IconButton
                        color="primary"
                        target="_blank"
                        href="https://www.tiktok.com/yominokunixyz"
                      >
                        <FontAwesomeIcon icon={faTiktok} size={"sm"} />
                      </IconButton>
                    </MenuItem> */}
                  </Menu>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="column"
                    spacing={-0.5}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Typography color="primary" variant="h5" align="center">
                        黄泉の国
                      </Typography>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        align="center"
                      >
                        YOMI NO KUNI
                      </Typography>
                    </Link>
                  </Stack>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};
