import { Box, Button, Stack, Typography } from "@mui/material";

import aichan from "../assets/art/ai-large.png";

// Effects
import { SakuraEffect } from "./SakuraEffect";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  useEffect(() => {
    // document.body.classList.add("bg-storm");
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto"; // cleanup or run on page unmount
      document.body.classList.remove("bg-storm");
      document.body.classList.remove("bg-storm-reverse");
    };
  }, []);

  const [storm, setStorm] = useState(false);

  const handleStyleChange = () => {
    if (storm) {
      document.body.classList.add("bg-storm-reverse");
      document.body.classList.remove("bg-storm");
    } else {
      document.body.classList.add("bg-storm");
      document.body.classList.remove("bg-storm-reverse");
    }
    setStorm(!storm);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     handleStyleChange();
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [storm]);

  return (
    <div className="full-screen">
      <div id="sakuraDiv" style={{ overflow: "clip" }}>
        <SakuraEffect />
      </div>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        minHeight={"80vh"}
      >
        <a
          target="_blank"
          href="https://x.com/yominokunixyz"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onMouseEnter={handleStyleChange}
            onMouseLeave={handleStyleChange}
            sx={{ cursor: "pointer" }}
          >
            <Box
              component="img"
              sx={{
                width: 127, //254
                borderRadius: "50%",
                imageRendering: "pixelated",
                marginBottom: -2,
              }}
              alt="ai-chan"
              src={aichan}
            />
            <Typography
              className={storm ? "glitch" : ""}
              variant="h1"
              color={storm ? "white" : "primary"}
              sx={{ fontSize: 90 }}
            >
              {storm && <span aria-hidden="true">黄泉の国</span>}
              黄泉の国
              {storm && <span aria-hidden="true">黄泉の国</span>}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={5}
            >
              <Typography
                variant="h4"
                color={storm ? "white" : "primary"}
                className={storm ? "glitch" : ""}
              >
                {storm && <span>Y O M I</span>} Y O M I
                {storm && <span>Y O M I</span>}
              </Typography>
              <Typography
                variant="h4"
                color={storm ? "white" : "primary"}
                className={storm ? "glitch" : ""}
              >
                {storm && <span>N O</span>} N O{storm && <span>N O</span>}
              </Typography>
              <Typography
                variant="h4"
                color={storm ? "white" : "primary"}
                className={storm ? "glitch" : ""}
              >
                {storm && <span>K U N I</span>} K U N I
                {storm && <span>K U N I</span>}
              </Typography>
            </Stack>
            <Typography
              color={storm ? "white" : "primary"}
              variant="subtitle1"
              className={storm ? "glitch" : ""}
            >
              {storm && <span>A fully onchain gaming experience</span>}A fully
              onchain gaming experience
              {storm && <span>A fully onchain gaming experience</span>}
            </Typography>
          </Stack>
        </a>
      </Stack>
    </div>
  );
};
