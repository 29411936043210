import { useEffect } from "react";
import { Sakura } from "../assets/effects/sakura/sakura";

export const SakuraEffect = () => {
  //Initialise sakura effect
  useEffect(() => {
    var sakura = new Sakura("body", { fallSpeed: 1 });
    return () => {
      sakura.stop();
    };
  }, []);

  return null;
};
